import { isPlatformBrowser } from '@angular/common';
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  PLATFORM_ID
} from '@angular/core';
import { Router } from '@angular/router';
import { MenuNav, SubMenu, User } from '@infrab4a/connect';
import { CartService } from '@infrab4a/connect-angular';
import { MensAuthenticationService } from 'src/app/services/mens-authentication.service';
import { StateService } from 'src/app/services/state.service';
import { BroadcastUtil } from 'src/app/utils/broadcast.util';

export type GlamPage = 'clube' | 'loja' | 'account';
@Component({
  selector: 'app-menu-mobile',
  templateUrl: './menu-mobile.component.html',
  styleUrls: ['./menu-mobile.component.scss']
})
export class MenuMobileComponent implements OnInit {
  @Input()
  userLogged: boolean;

  @Input()
  user: User;

  @Input()
  routesShop: Array<MenuNav>;

  @Input()
  brands: Array<{ label: string; path: string }>;

  @Input()
  loggedMenuItems: Array<any>;

  @Input()
  clubRoutes: Array<any>;

  @Input()
  helpMenu: Array<any>;

  @Output() menuChanged = new EventEmitter<boolean>();

  menuOpened: boolean;
  menuClosed = false;
  loggedMenuOpened = false;
  shopMenuOpened = false;
  clubMenuOpened = false;
  helpMenuOpened = false;
  brandsMenuOpened = false;
  glampointsMenuOpened = false;
  subMenuOpened = false;
  selectedMenu: SubMenu;
  selectedSubMenu: SubMenu;
  selectedSubChildMenu: SubMenu;
  subMenus: Array<SubMenu>;

  constructor(
    private router: Router,
    private cartService: CartService,
    private shopCartService: CartService,
    @Inject(PLATFORM_ID) private platform: object,
    private stateService: StateService,
    private authService: MensAuthenticationService
  ) {}

  ngOnInit(): void {
    BroadcastUtil.get('toggleMenuMobile').subscribe((e) => {
      this.toggleMenuMobile();
    });
    this.routesShop = this.routesShop?.filter((r) => r.label !== 'Marcas');
    this.shopMenuOpened = true;
  }

  goTo(route: string, query?: string): void {
    if (route.startsWith('http')) {
      window.open(route, '_blank');
      this.menuOpened = false;
      this.menuChanged.emit(this.menuOpened);
    } else {
      this.router
        .navigate([route], { ...(query && { queryParams: { [query]: 1 } }) })
        .then(() => {
          this.loggedMenuOpened = false;
          this.menuOpened = false;
          this.shopMenuOpened = false;
          this.clubMenuOpened = false;
          this.menuChanged.emit(this.menuOpened);
        });
    }
  }

  login(): void {
    this.toggleMenuMobile();
    this.router.navigate(['/login'], {
      queryParams: { redirectUrl: this.router.url }
    });
  }

  toggleMenuMobile(): void {
    if (this.menuOpened) {
      this.menuClosed = true;
      setTimeout(() => {
        this.menuClosed = false;
        this.menuOpened = false;
        this.menuChanged.emit(this.menuOpened);
      }, 400);
    } else {
      this.loggedMenuOpened = true;
      this.clubMenuOpened = false;
      this.helpMenuOpened = false;
      this.shopMenuOpened = false;
      this.brandsMenuOpened = false;
      delete this.selectedMenu;
      delete this.selectedSubMenu;
      this.menuOpened = true;
      this.menuChanged.emit(this.menuOpened);
    }
  }

  onOverlay(): void {
    this.toggleMenuMobile();
  }

  openLoggedMenu(): void {
    this.loggedMenuOpened = !this.loggedMenuOpened;
    this.clubMenuOpened = false;
    this.helpMenuOpened = false;
    this.shopMenuOpened = false;
    this.brandsMenuOpened = false;
  }

  openShopMenu(): void {
    this.shopMenuOpened = !this.shopMenuOpened;
    this.clubMenuOpened = false;
    this.helpMenuOpened = false;
    this.loggedMenuOpened = false;
  }

  openClubMenu(): void {
    this.clubMenuOpened = !this.clubMenuOpened;
    this.helpMenuOpened = false;
    this.shopMenuOpened = false;
    this.loggedMenuOpened = false;
  }

  openHelpMenu(): void {
    this.router.navigate(['/sac']);
    this.toggleMenuMobile();
  }

  openBrandsMenu(): void {
    this.brandsMenuOpened = !this.brandsMenuOpened;
  }

  async logout($event: Event): Promise<void> {
    if (isPlatformBrowser(this.platform)) {
      localStorage.clear();
      this.authService.logout();
    }
    this.shopCartService
      .updateUserCart(User.toInstance({ isSubscriber: false }))
      .subscribe();
    BroadcastUtil.get('balanceChanged').emit();
    this.router.navigate(['']);
  }

  selectMenu(subMenu: SubMenu): void {
    if (this.selectedMenu !== subMenu) {
      this.selectedMenu = subMenu;
    } else {
      delete this.selectedMenu;
    }
  }

  selectSubMenu(subMenu: SubMenu) {
    if (this.selectedSubMenu !== subMenu && subMenu.subChildMenu.length) {
      this.selectedSubMenu = subMenu;
    } else if (this.selectedSubMenu === subMenu) {
      delete this.selectedSubMenu;
    } else {
      this.goTo(subMenu.path);
    }
  }

  selectSecondSubMenu(subChildMenu: SubMenu) {
    this.goTo(subChildMenu.path);
  }
}
