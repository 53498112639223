import { RouterModule, Routes } from '@angular/router';

import { NgModule } from '@angular/core';
import CustomerShopsModule from '@pages/customer-shops/customer-shops.module';
import { AuthGuard } from 'src/app/security/auth-guard';
import { CheckoutLayoutComponent } from './layout/checkout-layout/checkout-layout.component';
import { MainLayoutComponent } from './layout/main-layout/main-layout.component';
import FavoritesModule from './pages/favorites/favorites.module';

const routes: Routes = [
  {
    path: '',
    component: MainLayoutComponent,

    children: [
      {
        path: '',
        loadChildren: () =>
          import('@pages/home/home.module').then((m) => m.HomeModule)
      },
      {
        path: 'collections',
        loadChildren: () =>
          import('@pages/collections/collections.module').then(
            (m) => m.CollectionsModule
          )
      },
      {
        path: 'products/:slug',
        loadChildren: () =>
          import('@pages/product-detail/product-detail.module').then(
            (m) => m.ProductDetailModule
          )
      },
      {
        path: 'search/:key',
        loadChildren: () =>
          import('@pages/search-results/search-results.module').then(
            (m) => m.SearchResultsModule
          )
      },
      {
        path: 'brands',
        loadChildren: () =>
          import('@pages/brands/brands.module').then((m) => m.BrandsModule)
      },
      {
        path: 'lojinhas',
        loadChildren: () => CustomerShopsModule
      },
      {
        path: 'minha-conta',
        loadChildren: () =>
          import('@pages/user-settings/user-settings.module').then(
            (m) => m.UserSettingsModule
          )
      },
      {
        path: 'meus-favoritos',
        loadChildren: () => import('./pages/favorites/favorites.module')
      },
      {
        path: 'minha-conta/login',
        loadChildren: () =>
          import('@pages/login/login.module').then((m) => m.LoginModule)
      },
      {
        path: 'minha-conta/register',
        loadChildren: () =>
          import('@pages/register/register.module').then(
            (m) => m.RegisterModule
          )
      },
      {
        path: 'minha-conta/meus-dados/alterar-senha',
        loadChildren: () =>
          import('@pages/forgot-password/forgot-password.module').then(
            (m) => m.ForgotPasswordModule
          )
      },
      {
        path: 'pages/sobre-nos',
        loadChildren: () =>
          import('@pages/about-us/about-us.module').then((m) => m.AboutUsModule)
      },
      {
        path: 'pages/politica-de-privacidade',
        loadChildren: () =>
          import('@pages/privacy-policy/privacy-policy.module').then(
            (m) => m.PrivacyPolicyModule
          )
      },
      {
        path: 'pages/termos-e-condicoes-de-uso',
        loadChildren: () =>
          import('@pages/terms/terms.module').then((m) => m.TermsModule)
      },
      {
        path: 'pages/clube',
        loadChildren: () =>
          import('@pages/club/club.module').then((m) => m.ClubModule)
      },
      {
        path: 'sac',
        loadChildren: () =>
          import('@pages/sac/sac.module').then((m) => m.SacModule)
      },
      {
        path: 'caixa-do-clube',
        loadChildren: () =>
          import('@pages/club-box/club-box.module').then((m) => m.ClubBoxModule)
      },
      {
        path: 'pages/imprensa',
        loadChildren: () =>
          import('@pages/imprensa/imprensa.module').then(
            (m) => m.ImprensaModule
          )
      },
      {
        path: 'login',
        loadChildren: () =>
          import('@pages/login/login.module').then((m) => m.LoginModule)
      },
      {
        path: 'favoritos/:slug',
        loadChildren: () => FavoritesModule
      }
      // {
      //   path: "assinatura",
      //   redirectTo: "user-settings/assinatura",
      // }
    ]
  },
  {
    path: '',
    component: CheckoutLayoutComponent,
    children: [
      {
        path: 'checkout',
        loadChildren: () =>
          import('@pages/checkout/checkout.module').then(
            (m) => m.CheckoutModule
          )
      },
      {
        path: 'checkout/:orderId/success/:url',
        loadChildren: () =>
          import('@pages/checkout-success/checkout-success.module').then(
            (m) => m.CheckoutSuccessModule
          )
      },
      {
        path: 'assine',
        loadChildren: () =>
          import(
            '@pages/checkout-subscription/checkout-subscription.module'
          ).then((m) => m.CheckoutSubscriptionModule)
      },
      {
        path: 'assine/:subId/success',
        loadChildren: () =>
          import(
            '@pages/checkout-subscription-success/checkout-subscription-success.module'
          ).then((m) => m.CheckoutSubscriptionSuccessModule)
      }
    ]
  },
  {
    path: 'promocoes',
    loadChildren: () =>
      import('@pages/promotions/promotions.module').then(
        (m) => m.PromotionsModule
      )
  },
  {
    path: '**',
    pathMatch: 'full',
    loadChildren: () =>
      import('@pages/wrong-route/wrong-route.module').then(
        (m) => m.WrongRouteModule
      )
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [AuthGuard]
})
export class AppRoutingModule {}
